// app.js
// This is where the state is connected to the view-heirarchy

import React from 'react';

import {
  OrganizationManager,
} from 'yggio-react-components';

const gotoLogin = () => {
  console.log('gotoLogin was cicked');
};

const App = () => (
  <OrganizationManager
    gotoLogin={gotoLogin}
  />
);

export default App;
